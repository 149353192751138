/**
 * @author Irakli Kandelaki
 */

import { ref } from "vue";
import type { Ref } from "vue";

enum DIRECTIONS {
    L = "LEFT",
    R = "RIGHT",
}

interface TouchValues {
    touchX: number;
    touchY: number;
}

export const handleSwipeDirection = (): {
    handleSwipeStart: (e: TouchEvent) => TouchValues;
    handleSwipeEnd: (e: TouchEvent) => TouchValues;
    direction: Ref<string>;
} => {
    const direction: Ref<string> = ref(null);
    let touchStartX: number = 0;
    let touchEndX: number = 0;

    const handleSwipeStart = (e: TouchEvent): TouchValues => {
        touchStartX = e.changedTouches[0].pageX;

        return {
            touchX: e.changedTouches[0].pageX,
            touchY: e.changedTouches[0].pageY,
        };
    };

    const handleSwipeEnd = (e: TouchEvent): TouchValues => {
        touchEndX = e.changedTouches[0].pageX;
        if (
            Math.abs(touchEndX) > Math.abs(touchStartX) &&
            Math.abs(touchEndX) - Math.abs(touchStartX) >= 100
        ) {
            direction.value = null;
            setTimeout(() => {
                direction.value = DIRECTIONS.R;
            }, 0);
        } else if (
            Math.abs(touchStartX) > Math.abs(touchEndX) &&
            Math.abs(touchStartX) - Math.abs(touchEndX) >= 100
        ) {
            direction.value = null;
            setTimeout(() => {
                direction.value = DIRECTIONS.L;
            }, 0);
        }

        return {
            touchX: e.changedTouches[0].pageX,
            touchY: e.changedTouches[0].pageY,
        };
    };

    return {
        handleSwipeStart,
        handleSwipeEnd,
        direction,
    };
};
